export const UPDATE_CODE = 'UPDATE_CODE';

export const OBFUSCATE = 'OBFUSCATE';
export const OBFUSCATE_PENDING = 'OBFUSCATE_PENDING';
export const OBFUSCATE_FULFILLED = 'OBFUSCATE_FULFILLED';
export const OBFUSCATE_REJECTED = 'OBFUSCATE_REJECTED';

export const TOGGLE_COMPACT_CODE = 'TOGGLE_COMPACT_CODE';
export const TOGGLE_SELF_DEFENDING = 'TOGGLE_SELF_DEFENDING';
export const TOGGLE_DISABLE_CONSOLE_OUTPUT = 'TOGGLE_DISABLE_CONSOLE_OUTPUT';

export const TOGGLE_DEBUG_PROTECTION = 'TOGGLE_DEBUG_PROTECTION';
export const TOGGLE_DEBUG_PROTECTION_INTERVAL = 'TOGGLE_DEBUG_PROTECTION_INTERVAL';

export const SET_SOURCEMAP_MODE = 'SET_SOURCEMAP_MODE';
export const SET_SOURCEMAP_BASE_URL = 'SET_SOURCEMAP_BASE_URL';
export const SET_SOURCEMAP_FILE_NAME = 'SET_SOURCEMAP_FILE_NAME';

export const SET_STRING_ARRAY_THRESHOLD = 'SET_STRING_ARRAY_THRESHOLD';
export const TOGGLE_ROTATE_STRING_ARRAY = 'TOGGLE_ROTATE_STRING_ARRAY';
export const TOGGLE_STRING_ARRAY = 'TOGGLE_STRING_ARRAY';
export const SET_STRING_ARRAY_ENCODING = 'SET_STRING_ARRAY_ENCODING';

export const ADD_DOMAIN_LOCK = 'ADD_DOMAIN_LOCK';
export const REMOVE_DOMAIN_LOCK = 'REMOVE_DOMAIN_LOCK';

export const ADD_RESERVED_NAME = 'ADD_RESERVED_NAME';
export const REMOVE_RESERVED_NAME = 'REMOVE_RESERVED_NAME';

export const ADD_RESERVED_STRING = 'ADD_RESERVED_STRING';
export const REMOVE_RESERVED_STRING = 'REMOVE_RESERVED_STRING';

export const SET_SEED = 'SET_SEED';

export const SET_CONTROL_FLOW_FLATTENING_THRESHOLD = 'SET_CONTROL_FLOW_FLATTENING_THRESHOLD';
export const TOGGLE_CONTROL_FLOW_FLATTENING = 'TOGGLE_CONTROL_FLOW_FLATTENING';

export const SET_DEAD_CODE_INJECTION_THRESHOLD = 'SET_DEAD_CODE_INJECTION_THRESHOLD';
export const TOGGLE_DEAD_CODE_INJECTION = 'TOGGLE_DEAD_CODE_INJECTION';

export const TOGGLE_UNICODE_ESCAPE_SEQUENCE = 'TOGGLE_UNICODE_ESCAPE_SEQUENCE';

export const TOGGLE_RENAME_GLOBALS = 'TOGGLE_RENAME_GLOBALS';

export const SET_TARGET = 'SET_TARGET';

export const SET_IDENTIFIER_NAMES_GENERATOR = 'SET_IDENTIFER_NAMES_GENERATOR';

export const SET_IDENTIFIERS_PREFIX = 'SET_IDENTIFIERS_PREFIX';

export const TOGGLE_TRANSFORM_OBJECT_KEYS = 'TOGGLE_TRANSFORM_OBJECT_KEYS';
